:root {
  --primary-color: #1DB954;
  --primary-dark: #1aa34a;
  --text-color: #FFFFFF;
  --background: #121212;
  --card-background: rgba(255, 255, 255, 0.1);
  --card-hover: rgba(255, 255, 255, 0.2);
}

body {
  margin: 0;
  background: var(--background);
  color: var(--text-color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App {
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
}

.gradient-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
}

.moving-gradient {
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  background: radial-gradient(circle at center,
    rgba(29, 185, 84, 0.15) 0%,
    rgba(29, 185, 84, 0.05) 25%,
    transparent 70%);
  animation: rotate 30s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-header {
  position: relative;
  z-index: 1;
  padding: 2rem;
}

.logo {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-color);
  text-decoration: none;
  letter-spacing: -0.5px;
}

.logo span {
  color: var(--primary-color);
}

.main-content {
  position: relative;
  z-index: 1;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  transition: transform 0.5s ease;
}

.main-content.expanded {
  transform: translateY(-2rem);
}

.hero {
  text-align: center;
  margin-bottom: 4rem;
}

.hero h1 {
  font-size: 4rem;
  font-weight: 900;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  letter-spacing: -2px;
}

.gradient-text {
  background: linear-gradient(45deg, var(--primary-color), #4eda8b);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.hero-subtitle {
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.7);
  max-width: 600px;
  margin: 0 auto;
}

.form-container {
  max-width: 800px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 1rem;
  backdrop-filter: blur(10px);
}

.input-wrapper {
  position: relative;
  margin-bottom: 1rem;
}

.purpose-input {
  width: 100%;
  padding: 1.5rem;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.05);
  color: var(--text-color);
  font-size: 1.1rem;
  resize: none;
  height: 100px;
  transition: all 0.3s ease;
  box-sizing: border-box;
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.purpose-input:focus {
  outline: none;
  border-color: var(--primary-color);
  background: rgba(255, 255, 255, 0.1);
}

.input-suggestions {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: left;
  gap: 2px;
  margin-top: 2px;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  height: 30px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.input-suggestions::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .input-suggestions {
    mask-image: linear-gradient(to right, black 80%, transparent 100%);
    -webkit-mask-image: linear-gradient(to right, black 80%, transparent 100%);
  }
}

.submit-button {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 0.5rem;
  background: var(--primary-color);
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background: var(--primary-dark);
  transform: translateY(-2px);
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.genres-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
}

.genre-card {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.genre-card a {
  text-decoration: none;
  color: var(--text-color);
}

.genre-card-content {
  background: var(--card-background);
  padding: 1.5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

.genre-card-content:hover {
  background: var(--card-hover);
  transform: translateY(-2px);
}

.genre-name {
  font-weight: 500;
}

.genre-arrow {
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.3s ease;
}

.genre-card-content:hover .genre-arrow {
  opacity: 1;
  transform: translateX(0);
}

.error-message {
  color: #ff4444;
  text-align: center;
  margin-top: 1rem;
  padding: 1rem;
  background: rgba(255, 68, 68, 0.1);
  border-radius: 0.5rem;
}

.recommendation-results {
  margin-top: 3rem;
  animation: fadeIn 0.5s ease;
}

.recommendation-results h3 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  color: var(--text-color);
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }
  
  .form-container {
    padding: 1rem;
  }
  
  .genres-grid {
    grid-template-columns: 1fr;
  }
}

.genres-container {
  columns: 2 300px;
  column-gap: 1.5rem;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 1rem;
}

.genre-group {
  break-inside: avoid;
  margin-bottom: 1.5rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 1rem;
  padding: 1.25rem;
  transform: translateY(20px);
  opacity: 0;
  animation: fadeInUp 0.5s ease forwards;
  transition: all 0.3s ease;
  min-height: 160px;
  display: flex;
  flex-direction: column;
}

.genre-group:hover {
  background: rgba(255, 255, 255, 0.05);
  transform: translateY(-2px);
}

.related-artists {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
  scrollbar-width: none;
}

.genre-pill,
.artist-pill {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.2s ease;
  color: var(--text-color);
  backdrop-filter: blur(10px);
  width: fit-content;
  border-radius: 8px;
  font-size: 0.85rem;
  padding: 0.5rem 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin: 0.25rem;
}

.genre-pill.primary {
  background: rgba(29, 185, 84, 0.15);
  border: 1px solid rgba(29, 185, 84, 0.3);
}

.genre-pill.secondary {
  background: rgba(255, 255, 255, 0.08);
}

.artist-pill {
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.genre-pill.primary:hover,
.artist-pill:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-1px);
}

.genre-pill.primary::after {
  content: '→';
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.2s ease;
}

.genre-pill.primary:hover::after {
  opacity: 1;
  transform: translateX(0);
}

.genre-pill.secondary:hover {
  background: rgba(255, 255, 255, 0.12);
  transform: translateY(-1px);
}

.subgenres {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.artist-pill,
.genre-pill.secondary {
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
}


@media (max-width: 768px) {
  .genres-container {
    columns: 1;
    padding: 0 1rem;
  }

  .genre-group {
    margin-bottom: 1rem;
    padding: 1rem;
    height: 140px;
  }

  .genre-pill.primary {
    font-size: 1rem;
    padding: 0.6rem 0.8rem;
  }

  .genre-pill.secondary {
    font-size: 0.8rem;
    padding: 0.4rem 0.6rem;
  }

  .subgenres {
    max-height: 70px;
  }
}

.primary-genre .genre-card {
  background: linear-gradient(135deg, #2c3e50, #3498db);
  transform: scale(1.05);
}

.related-genres {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 0.5rem;
  margin-left: 2rem;
}

.genre-card.related {
  background: linear-gradient(135deg, #34495e, #2c3e50);
  font-size: 0.9em;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.activity-suggestion {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 4px 8px;
  margin: 0 4px;
  font-size: 0.9em;
  transition: color 0.2s;
  white-space: nowrap;
  flex-shrink: 0;
}

.activity-suggestion:hover {
  color: #fff;
  text-decoration: underline;
}

.input-suggestions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: left;
  gap: 2px;
  margin-top: 2px;
}

.try-text {
  color: #666;
  margin-right: 4px;
  font-size: 0.9em;
}

.separator {
  color: #666;
  margin: 0 1px;
  font-size: 0.9em;
}

.advanced-controls {
  margin-top: 1rem;
}

.toggle-advanced {
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.7);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  width: 100%;
}

.toggle-advanced:hover {
  background: rgba(255, 255, 255, 0.05);
  color: white;
}

.controls-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1.5rem;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 0.5rem;
  animation: fadeIn 0.3s ease;
}

.control-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.control-group label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.mood-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, #ff6b6b, #4ecdc4);
  border-radius: 2px;
  outline: none;
}

.mood-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.mood-slider::-webkit-slider-thumb:hover {
  transform: scale(1.2);
}

.mood-labels {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 0.5rem;
}

.button-group {
  display: flex;
  gap: 0.5rem;
}

.complexity-btn {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: none;
  color: rgba(255, 255, 255, 0.7);
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.complexity-btn.active {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}

.decade-select {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 100%;
  cursor: pointer;
}

.decade-select option {
  background: #121212;
}

@media (max-width: 768px) {
  .controls-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1rem;
  }
}

